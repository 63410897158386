<div class="mainWrapper">
  @if (showHeader) {
    <adi-header-lib
      (loader)="showHideLoader($event)"
      [apiEndpoint]="apiEndpoint"
      [source]="source"
    ></adi-header-lib>
    @if (showAccountSelector) {
      <div class="account-selector">
        <alcon-account-selector-lib
          (loader)="showHideLoader($event)"
          [apiEndpoint]="apiEndpoint"
          [source]="source"
        >
        </alcon-account-selector-lib>
      </div>
    }
  }

  <router-outlet />

  @if (showFooter && !showGuestFooter) {
    <adi-footer-lib
      [apiEndpoint]="apiEndpoint"
      [source]="source"
    ></adi-footer-lib>
  }
  @if (showGuestFooter) {
    <adi-footer></adi-footer>
  }
  @if (loader) {
    <adi-loader> </adi-loader>
  }
</div>
